import memoize from 'memoize-one';

import ProfileClient from '@atlaskit/profilecard/client';

import type { FeatureFlagsType } from '@confluence/session-data';

import { UserProfileClientForConfluence } from './UserProfileClientForConfluence';

const DIRECTORY_GRAPHQL_PATH = '/gateway/api/directory/graphql';

const USER_PROFILE_CONFIG = {
	url: DIRECTORY_GRAPHQL_PATH,
	cacheSize: 100,
	cacheMaxAge: 5 * 60 * 1000, // - 5 minutes
};

export const getProfileClient = memoize((cloudId: string, _: FeatureFlagsType): ProfileClient => {
	const profileClientConfig = {
		...USER_PROFILE_CONFIG,
		productIdentifier: 'confluence',
		cloudId,
	};

	return new ProfileClient(profileClientConfig, {
		userClient: new UserProfileClientForConfluence(profileClientConfig),
	});
});
