import type { FC, ReactNode } from 'react';
import React, { useState, Fragment, useMemo } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { ProfileCardAction } from '@atlaskit/profilecard/types';
import ProfileCardTrigger from '@atlaskit/profilecard/user';

import { useViewUserProfile } from '@confluence/global-operations';
import { CONTEXT_PATH } from '@confluence/named-routes';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useSessionData } from '@confluence/session-data';
import { FlagsStateContainer } from '@confluence/flags';

import { FeedFollow, useFeedFollow } from './FeedFollow';
import { getProfileClient } from './getProfileClient';
import { getAtlassianPeopleProfileUrl, getAtlassianPeopleProfileUrlAsync } from './helpers';
import { FeedFollowErrorDisplay } from './FeedFollow/FeedFollowErrorDisplay';

const i18n = defineMessages({
	viewProfile: {
		id: 'profile.card.viewProfile',
		defaultMessage: 'View profile',
		description: 'Text for the view profile button on the profile card',
	},
});

export type TriggerPosition =
	| 'bottom-start'
	| 'bottom'
	| 'bottom-end'
	| 'left-start'
	| 'left'
	| 'left-end'
	| 'top-end'
	| 'top'
	| 'top-start'
	| 'right-end'
	| 'right'
	| 'right-start';

export type Trigger = 'click' | 'hover';

type ProfileCardWrapperProps = {
	userId?: string | null;
	position?: TriggerPosition;
	isAnonymous?: boolean;
	canViewProfile?: boolean;
	trigger?: Trigger;
	children: ReactNode;
	fullName?: string;
	disabledAriaAttributes?: boolean;
	onVisibilityChange?: (isVisible: boolean) => void;
	offset?: [number, number];
	ariaLabel?: string;
};

export const getViewProfileAction: (params: {
	push: (url: string, forceReload?: boolean | undefined) => void;
	userId?: string | null;
}) => ProfileCardAction = ({ push, userId }) => ({
	label: <FormattedMessage {...i18n.viewProfile} />,
	id: 'view-profile',
	callback: () => getAtlassianPeopleProfileUrlAsync(userId as string).then(push),
	shouldRender: (userData: any) => {
		// missing/deleted/corrupted users don't return any data or status
		if (!userData) return false;

		return userData.status && userData.status !== 'inactive';
	},
	link: getAtlassianPeopleProfileUrl(userId, '', CONTEXT_PATH),
});

export const getFollowUserAction: (params: {
	loggedInUserId: string | null;
	getToggleFollowUser: (
		userId: string | null | undefined,
		followed: boolean,
		source: string,
	) => () => void;
	renderFeedFollow: boolean;
	userId?: string | null;
	followedUsers?: (string | null)[];
}) => ProfileCardAction = ({
	loggedInUserId,
	getToggleFollowUser,
	renderFeedFollow,
	userId,
	followedUsers,
}) => {
	const followed = !!(userId && followedUsers?.includes(userId));

	return {
		label: <FeedFollow followed={followed} />,
		id: 'feed-follow-user',
		callback: getToggleFollowUser(userId, followed, 'profileCard'),
		shouldRender: (userData) =>
			renderFeedFollow &&
			Boolean(userId) &&
			userId !== loggedInUserId &&
			userData?.status === 'active',
	};
};

export const ProfileCardWrapper: FC<ProfileCardWrapperProps> = ({
	position = 'bottom-start',
	userId,
	isAnonymous = false,
	canViewProfile = true,
	children,
	trigger = 'hover',
	fullName,
	disabledAriaAttributes,
	onVisibilityChange,
	offset,
	ariaLabel,
}) => {
	const { push } = useRouteActions();
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();

	const { userId: loggedInUserId, cloudId, featureFlags } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isVisible, setIsVisible] = useState(false);

	const { feedConfigError, followedUsers, getToggleFollowUser, mutationError, renderFeedFollow } =
		useFeedFollow();

	const viewProfileAction = useMemo(() => getViewProfileAction({ push, userId }), [push, userId]);

	const followUserAction = useMemo(
		() =>
			getFollowUserAction({
				loggedInUserId,
				getToggleFollowUser,
				renderFeedFollow,
				userId,
				followedUsers,
			}),
		[loggedInUserId, getToggleFollowUser, renderFeedFollow, userId, followedUsers],
	);

	// Should display profile?
	if (
		isAnonymous ||
		!canViewProfile ||
		!userId ||
		!loggedInUserId ||
		!hasViewUserProfilePermission
	) {
		return <>{children}</>;
	}

	const reportingLinesClickCallback = (clickedUserId: string) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'profileCardWrapper',
				action: 'clicked',
				actionSubject: 'viewProfile',
				actionSubjectId: 'userProfileCard',
				attributes: {
					clickTarget: 'userReportingLine',
				},
			},
		}).fire();
		if (window.self !== window.top) {
			window.open(
				getAtlassianPeopleProfileUrl(clickedUserId, '', CONTEXT_PATH),
				'_blank',
				'noreferrer',
			);
		} else {
			getAtlassianPeopleProfileUrlAsync(clickedUserId).then(push, () => {});
		}
	};

	return (
		<Subscribe to={[FlagsStateContainer]}>
			{(flags: FlagsStateContainer) => (
				<Fragment>
					<FeedFollowErrorDisplay
						feedConfigError={feedConfigError}
						mutationError={mutationError}
						showErrorFlag
					/>
					<ProfileCardTrigger
						cloudId={cloudId}
						userId={userId}
						resourceClient={getProfileClient(cloudId, featureFlags)}
						position={position}
						trigger={trigger}
						actions={[viewProfileAction, followUserAction]}
						reportingLinesProfileUrl={`${CONTEXT_PATH}/people`}
						onReportingLinesClick={(user) => reportingLinesClickCallback(user.accountIdentifier)}
						addFlag={(flag) => flags.showFlag(flag)}
						prepopulatedData={{
							fullName,
						}}
						disabledAriaAttributes={disabledAriaAttributes}
						isVisible={isVisible}
						onVisibilityChange={
							onVisibilityChange ||
							((changedIsVisible) => {
								setIsVisible(changedIsVisible);
							})
						}
						offset={offset}
						testId="profileCardTrigger"
						viewingUserId={loggedInUserId}
						ariaLabel={ariaLabel}
					>
						{children}
					</ProfileCardTrigger>
				</Fragment>
			)}
		</Subscribe>
	);
};
